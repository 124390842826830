import { getCleanProductHandle } from '@/utils/helpers';
import WithoutSSR from '../without-ssr/without-ssr';
import styles from './bv.module.css';

export type BvInlineRatingProps = React.HTMLAttributes<HTMLDivElement> & {
  slug?: string;
};

export const BvInlineRating = ({ slug, ...props }: BvInlineRatingProps) => {
  if (!slug) {
    return null;
  }

  return (
    <WithoutSSR>
      <div className="mt-4 min-h-[18px]" {...props}>
        <div
          className={styles.inlineRating}
          data-bv-show="inline_rating"
          data-bv-product-id={getCleanProductHandle(slug)}
        />
      </div>
    </WithoutSSR>
  );
};
